<template>
  <travio-center-container pageTitle="Add Integration Settings">
    <vx-card>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Setting Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.settingName" v-validate="'required|max:25'" name="Setting Name" />
          <span class="text-danger text-sm">{{ errors.first('Setting Name') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Setting Value</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.settingValue" v-validate="'required|max:500'" name="Setting Value" />
          <span class="text-danger text-sm">{{ errors.first('Setting Value') }}</span>
        </div>
      </div>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click='onSubmit' class="mt-4">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      form: new Form({
        settingName: '',
        settingValue: '',
      })
    }
  },
  props: {
    integrationId: { required: true },
    supplierId: { required: true },
    applicationId: { required: true }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  mounted () {
    //Check if user has acccess to the company
    const hasAccess = this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)
    if(!hasAccess) {
      this.$router.push('/error-404')
      return
    }
  },
  methods: {
    onSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.post(`api/supplierintegrations/apps/${this.applicationId}/integrations/${this.integrationId}/settings`)
            .then(response => {
              this.$_notifySuccess('Integration settings successfully saved');
              this.returnToIntegrationsPage()
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    returnToIntegrationsPage() {
      this.$router.push({ name: 'supplier-integrations-edit', 
        params: { 
          integrationId: this.integrationId,
          supplierId: this.supplierId,
          applicationId: this.applicationId,
          tabIndexProp: 1
        }
      })
    },
    handleCancel () {
      this.returnToIntegrationsPage()
    }
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }
  /* .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
    background: #787879;
    border: none;
    color: #664f39;
    text-transform: lowercase;
    font-variant: small-caps;
  }

  .style-chooser .vs__clear,
  .style-chooser .vs__open-indicator {
    fill: #394066;
  } */
</style>